import styled from 'styled-components';
import { CategoryPopupStyling } from 'containers/FilterDrawer/FilterDrawer.style';

export const ModalPopup = `

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  @keyframes slide-right {
    0% {
      transform: translateX(50px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }



  .popup-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000000000 !important;
  }

  .popup-content {
    max-width: min(90vw, 700px);
    max-height: 90vh;
    overflow-y: scroll;
    border-radius: 10px;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

  ${CategoryPopupStyling}

  .shadow-popover {
    box-shadow: 0px 2px 5px 0 rgb(0 0 0 / 40%);
    border: none !important;
    border-radius: 5px !important;
    
    .popover-body {
      padding: 10px;
      border-radius: 5px;
      background-color: var(--primary-background-color);
    }

    .arrow {
      visibility: hidden;
    }
  }

`;

export const ModalClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 25px;
  z-index: 1;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  border: 0;
  outline: none;
  display: inline-block;
  
  svg {
    width: 12px;
    height: 12px;
    color: var(--devo-red);
  }
`;

export const PopupWrapper = styled.div`
  width: min(90vw, 600px);
  min-height: 150px;
  background-color: var(--background-color);

  a {
    color: var(--devo-red);
  }

`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  margin: 0;
  background-color: var(--background-color);
  border-bottom: 1px var(--separator-color) solid;

  h2 {
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  padding: 20px;
  
  .sharing-options {
    margin: 20px 0 30px;
    display: flex;
    justify-content: center;
    
    a {
      cursor: pointer;
    }
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  padding: 15px;
  text-align: center;
`;

type SpacerProps = { height: number; }
export const Spacer = styled.div<SpacerProps>`
  width: 100%;
  height: ${props => props.height || 20}px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  
  button {
    width: 100%;
    margin-right: 15px;
    padding: 14px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: top;

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  button:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    flex-flow: column;

    button {
      margin-right: 0;
      margin-bottom: 10px;
    }

    button:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CancelButton = styled.button`
  background-color: #fff;
  border: solid 1px #D4D4D4;
  color: #EE504F;

  .loader {
    margin: auto;
  }
`;

export const ConfirmButton = styled.button`
  border: none;
  background-color: #EE504F;
  color: #fff;

  .loader {
    margin: auto;
  }
`;