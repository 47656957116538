import styled from 'styled-components';

export const CategoryPopupStyling = `
  .category-popup-overlay {

  }

  .category-popup-content {
    max-height: 100vh;
    height: 100vh;
    width: 500px;
    margin: 0 0 0 auto !important;
    border-radius: 0;
    -webkit-animation: slide-right 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px 25px 30px;
  border-bottom: 1px var(--separator-color) solid;

  a {
    cursor: pointer;
  }

  svg {
    color: var(--devo-red);
  }
`;

const Content = styled.div`
  padding: 30px;

  .fromToFields {
    display: flex;

    input:first-child {
      margin-right: 20px;
    }
  }

  .loader {
    margin: 10px auto;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin: 50px 0 20px;

  &:first-child {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--devo-red);
    font-weight: 600;
  }

  button:focus {
    outline: none;
  }
`;

const Radio = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  a:last-child {
    cursor: pointer;
    margin-left: 10px;
  }
`;

interface SizesProps { selected: string; }
const Sizes = styled.div<SizesProps>`
  #${props => props.selected || 'none'} {
    background-color: var(--separator-color);
  }
`;

const SizeButton = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: 1px var(--separator-color) solid;
  border-radius: 6px;
  margin: 0 10px 20px 0;

  &:hover {
    border-color: var(--devo-red);
    border-width: 2px;
    padding: 9px 19px;
  }

  &:focus {
    outline: none;
  }
`;

const Styling = {
  Wrapper,
  Header,
  Content,
  Section,
  Radio,
  Sizes,
  SizeButton,
};

export default Styling;