import styled, { createGlobalStyle } from 'styled-components';
import { MobileDrawerModal } from 'containers/AppLayout/AppLayout.style';
import { ModalPopup } from 'components/Popup/Popup.style';
import { Colors, Spacings, Other } from './constants';

export const GlobalStyle = createGlobalStyle`
  :root {
    ${Colors}
    ${Spacings}
    ${Other}
  }

  ${MobileDrawerModal}
  ${ModalPopup}

  .sticky-inner-wrapper {
    z-index: 10;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
`;

export const Content = styled.div`
    width: 700px;
    margin: 20px auto 0 auto;
    min-height: calc(100vh - 90px);
`;

export const TabHeader = styled.div`
  @media (min-width: 768px) {
    padding: 0 20px !important;
  }

  .nav {
    border: none;
    padding-bottom: 20px;
    position: sticky;
    background-color: #fff;
    z-index: 1000;

    .nav-item {
      border: none;

      h4 {
        margin: 0;
        padding-bottom: 5px;
        color: #757575;
      }

      .nav-item:focus {
        outline: none;
      }
    }
  
    .active h4 {
      border-bottom: 2px #EE504F solid;
      color: #EE504F;
      font-weight: 700;
    }

    .nav-item:hover h4 {
      color: #EE504F;
    }

    .nav-item:focus {
      outline: none;
    }
  }

  .tab-content {
    margin: 0 5px;
  }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 30px;
`;

export const Label = styled.p`
`;

export const Label2 = styled.p`
  color: #757575;
`;

export const Subheadline = styled.h4`
  font-size: 14px;
`;